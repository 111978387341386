﻿import * as assetService from "@common/services/assetService"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
var DigiLean = angular.module('DigiLean'); DigiLean.controller('dataVisualizationTypeController', ['$scope', '$sce', '$stateParams', '$filter', '$translate', 'areaService', 'projectService','NgTableParams','qualityService',
    function ($scope, $sce,$stateParams, $filter, $translate, areaService, projectService, NgTableParams, qualityService) {
        $scope.visualizationType = $stateParams.type;

        $scope.columns = [];
        $scope.totalValues = 0;

        $scope.buildColumns = function () {
            $scope.columns = [
                {
                    title: $translate.instant("COMMON_DATE"),
                    field: 'date',
                    visible: true,
                    sortable: 'date',
                    getValue: renderValue,
                    dataType: "date"
                },
                {
                    title: $translate.instant("COMMON_REGISTRATION"),
                    field: 'lastModified',
                    visible: true,
                    sortable: 'lastModified',
                    getValue: renderValue,
                    dataType: "timestamp"
                },
                {
                    title: $translate.instant("COMMON_VALUE"),
                    field: 'status',
                    sortable: 'status',
                    getValue: renderValue,
                    visible: true,
                    dataType: "target"
                },
                {
                    title: $translate.instant("COMMON_ASSET"),
                    field: 'assetName',
                    sortable: 'assetName',
                    getValue: renderValue,
                    visible: true,
                    dataType: "text",
                    sourceColumn: "assetId"
                },
                {
                    title: $translate.instant("COMMON_PROJECT"),
                    field: 'projectName',
                    sortable: 'projectName',
                    getValue: renderValue,
                    visible: true,
                    dataType: "text",
                    sourceColumn: "projectId"
                },
                {
                    title: $translate.instant("COMMON_AREA"),
                    field: 'areaName',
                    sortable: 'areaName',
                    getValue: renderValue,
                    visible: true,
                    dataType: "text",
                    sourceColumn: "areaId"
                }
            ];
            var deleteColumn =
            {
                title: $translate.instant("COMMON_ACTION"),
                visible: true,
                dataType: "command"
            }
            $scope.columns.push(deleteColumn);
        }

        function renderValue($scope, row) {
            var item = this;
            var field = item.field;
            var value = row[field];
                
            switch (this.dataType) {
                case "date":
                case "timestamp":
                    return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`);
                case "target":
                    var html = `<button class="btn m-r-xs"></button>`;
                    switch (value) {
                        case 1:
                            html = `<button class="btn m-r-xs greencross-green"></button>`;
                            break;
                        case 2:
                            html = `<button class="btn m-r-xs greencross-blue"></button>`;
                            break;
                        case 3:
                            html = `<button class="btn m-r-xs greencross-yellow"></button>`;
                            break;
                        case 4:
                            html = `<button class="btn m-r-xs greencross-red"></button>`;
                            break;
                    }
                    return $sce.trustAsHtml(html);
                default:
                    return value;
            }
        }
        $scope.init = function () {
            if (!$scope.visualizationType) return;

            var listOperations = [];
            listOperations.push(assetService.getAllAssets(true).then(function (data) {
                $scope.assets = data;
            }));
            listOperations.push(areaService().getList(true).then(function (data) {
                $scope.areas = data;
            }));
            listOperations.push(projectService().getList(true).then(function (data) {
                $scope.projects = data;
            }));

            Promise.all(listOperations).then(() => {
                $scope.getLatestValues();
            })
        }
        $scope.init();

        $scope.getLatestValues = function (params) {
            if (!$scope.visualizationType) return;

            $scope.buildColumns();
            $scope.tableParams = new NgTableParams({
                sorting: { date: "desc" },
            },
                {
                    getData: $scope.getTableValues
                });

        }

        $scope.getTableValues = function (params) {

            var sorting = [];
            // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
            for (var propt in params._params.sorting) {
                sorting.push({
                    property: propt,
                    direction: params._params.sorting[propt]
                });
            }
            // We will build params based on the built-in NgTableParams + our sorting array
            var dbParams = {
                page: params._params.page,
                count: params._params.count,
                //filters: $scope.filters,
                //timePeriod: $scope.timeperiod,
                sorting: sorting
            }
            return qualityService().getTableValues($scope.visualizationType, dbParams).then(function (data) {
                angular.forEach(data.values, function (registration) {
                    var asset = $filter('filter')($scope.assets, { id: registration.assetId }, true);
                    if (asset && asset.length > 0) {
                        registration.assetName = asset[0].name;
                    } else {
                        if (registration.assetId) {
                            registration.assetName = registration.assetId;
                        }
                    }
                    var area = $filter('filter')($scope.areas, { id: registration.areaId }, true);
                    if (area && area.length > 0) {
                        registration.areaName = area[0].name;
                    } else {
                        if (registration.areaId) {
                            registration.areaName = registration.areaId;
                        }
                    }
                    var project = $filter('filter')($scope.projects, { id: registration.projectId }, true);
                    if (project && project.length > 0) {
                        registration.projectName = project[0].displayName;
                    } else {
                        if (registration.projectId) {
                            registration.projectName = registration.projectId;
                        }
                    }

                });
                $scope.totalValues = data.total;
                params.total(data.total); // recal. page nav controls
                return data.values;
            });
        }

        $scope.delete = function (element) {
            qualityService().deleteDateStatus(element.id).then(function () {
                var selected = $filter('filter')($scope.tableParams.data, { id: element.id });
                if (selected.length > 0) {
                    var index = $scope.tableParams.data.indexOf(selected[0]);
                    if (index > -1) {
                        $scope.tableParams.data.splice(index, 1);
                    }
                }
            });
            
        };
 
    }]);