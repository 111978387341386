import * as assetService from "@common/services/assetService"
import * as boardService from "@common/services/boardService"
angular
    .module('DigiLean')
    .directive("actionlist", ['$filter', '$uibModal', 'navigationService',
        function ($filter, $uibModal, navigationService) {
            return {
                templateUrl: 'actionlist.html',
                restrict: 'E',
                scope: {
                    'assetid': '=',
                    'boardid': '=',
                    'viewcontext': '=',  // context of view which Action list is supporting => viewcontext = { view: "Board", id = 452 }
                    'week': '=',
                    'readonly': '@',
                    'options': '<?'
                },
                link: function (scope, element, attrs) {

                    // Default modules
                    scope.hasBoard = navigationService().hasModule("BOARD");
                    scope.hasImprovement = navigationService().hasModule("IMPROVEMENT");
                    scope.hasDeviation = navigationService().hasModule("DEVIATION");
                    scope.hasA3 = navigationService().hasModule("A3");

                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.hasBoard = navigationService().hasModule("BOARD");
                        scope.hasImprovement = navigationService().hasModule("IMPROVEMENT");
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");
                        scope.hasA3 = navigationService().hasModule("A3");
                    });

                    scope.openBoardTasks = true;
                    scope.openImprovement = false;
                    scope.openDeviation = false;
                    scope.openA3 = false;
                    scope.taskCount = 0;
                    scope.taskOverdueCount = 0;
                    scope.improvementCount = 0;
                    scope.improvementOverdueCount = 0;
                    scope.deviationCount = 0;
                    scope.deviationOverdueCount = 0;
                    scope.a3Count = 0;
                    scope.a3OverdueCount = 0;

                    scope.filter = null;

                    function setHeight(targetHeight) {
                        if (!targetHeight) {
                            var actionListContainer = $(".actionlist-content");
                            var offset = actionListContainer.offset();
                            var windowHeight = $( window ).height();
                            var targetHeight = windowHeight - offset.top;
                            if (targetHeight < 500) targetHeight = 500;
                            actionListContainer.css("height", targetHeight);
                            
                        } else {
                            $(".actionlist-content").css("height", targetHeight);

                        }
                    }

                    scope.$watch("options", function (options) {
                        if (!options) return;
                        if (options.targetHeight) {
                            setHeight(options.targetHeight);
                        }
                        if (options.filter) {
                            if(options.filter.userId){
                                scope.filter = {userId : options.filter.userId};
                            } else if(options.filter.tags){
                                scope.filter = {tags : options.filter.tags};
                            } else if(options.filter.color){
                                scope.filter = {color : options.filter.color};
                            } else{
                                scope.filter = {};    
                            }
                            
                        } else {
                            scope.filter = {};
                        }
                    });

                    scope.$watch("assetid", function () {
                        if (!scope.assetid) return;
                        assetService.getList().then(function (assets) {
                            scope.assets = assets;
                            if (scope.assetid) {

                                var assets = $filter('filter')(scope.assets, { id: scope.assetid }, true);
                                if (assets.length === 1) {
                                    scope.selectedAsset = assets[0];
                                }
                                else {
                                    assetService.getById(scope.assetid).then(function (asset) {
                                        scope.selectedAsset = asset;
                                    });
                                }
                            }
                        });
                    });

                    scope.$watch("boardid", function () {
                        boardService.getUserListForBoard(scope.boardid).then(function (data) {
                            scope.users = data;
                        });
                    });

                    scope.subscribe("BoardActionListCountChanged", function (status) {
                        scope.taskCount = status.total;
                        scope.taskOverdueCount = status.overdue;
                    });

                    scope.subscribe("A3ActionListCountChanged", function (status) {
                        scope.a3Count = status.total;
                        scope.a3OverdueCount = status.overdue;
                    });

                    scope.subscribe("ImprovementActionListCountChanged", function (status) {
                        scope.improvementCount = status.total;
                        scope.improvementOverdueCount = status.overdue;
                    });

                    scope.subscribe("DeviationActionListCountChanged", function (status) {
                        scope.deviationCount = status.total;
                        scope.deviationOverdueCount = status.overdue;
                    });
                    
                    scope.toggleOpenBoardTasks = function () {
                        scope.openBoardTasks = !scope.openBoardTasks;
                    };
                    scope.toggleOpenImprovement = function () {
                        scope.openImprovement = !scope.openImprovement;
                    };
                    scope.toggleOpenDeviation = function () {
                        scope.openDeviation = !scope.openDeviation;
                    };
                    scope.toggleOpenA3 = function () {
                        scope.openA3 = !scope.openA3;
                    };

                    scope.changeAsset = function (asset) {
                        scope.assetid = asset.id;
                        scope.selectedAsset = asset;
                    };

                    scope.createNewTeamTask = function () {
                        var task = {
                            id: 0,
                            boardId: scope.boardid,
                            isActionItem: true, 
                            title: "",
                            text: "",
                            status: 'blank',
                            tags: ""
                        }
                        
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    var options = {
                                        canChangeBoard: true,
                                        showDueDate: true,
                                        users: scope.users
                                    }
                                    return options;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                        });                
                    };

                    scope.createNewImprovement = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newSuggestionForm.html',
                            controller: 'newSuggestionModalController',
                            resolve: {
                                suggestion: function () {
                                    return {
                                        id: 0,
                                        assetId: scope.assetid,
                                        title: "",
                                        text: "",
                                        suggestedBy: "",
                                        suggestionDate: new Date(),
                                        showComments: false,
                                        likes: [],
                                        comments: [],
                                    }
                                }

                            }
                        });
                    };

                    scope.createNewDeviation = function () {
                        var settings = { assetId: scope.assetid };
                        $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    };

                    scope.createNewA3 = function () {
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newA3Form.html',
                            controller: 'newA3ModalController',
                            resolve: {
                                a3: function () {
                                    return {
                                        id: 0,
                                        title: "",
                                        assetId: scope.assetid,
                                        category: "",
                                        goal: ""
                                    }
                                }

                            }
                        });

                    };
                }
            }
        }]);
